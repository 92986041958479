












































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { meetingsStore, teamsStore, contactsStore } from '@/store'
import MembersSelector from '../components/MembersSelector/index.vue'
import TdInput from 'td-ui/dist/TdInput.vue'
import {
  Contact,
  Meeting,
  Freq,
} from '@tada-team/tdproto-ts'
import { date } from 'quasar'
import { TabKey } from './types'

@Component({
  components: {
    MembersSelector,
    TdInput,
    MeetingDialogHead: () => import('./Head.vue'),
    MeetingDialogActions: () => import('./Actions.vue'),
    MeetingDialogError: () => import('./Error.vue'),
    DateSelector: () => import('./DateSelector.vue'),
    TypeSelector: () => import('./TypeSelector.vue'),
    Repeatability: () => import('./Repeatability/index.vue'),
    Tabs: () => import('./Tabs.vue'),
    MeetingWishList: () => import('./WishList.vue'),
    Info: () => import('./Info.vue'),
  },
})
export default class MeetingDialog extends Vue {
  private startDate = ''
  private endDate = ''
  private updatedMeeting: Meeting | null = null
  private isShowMode = false
  private tab: TabKey = 'cell'
  private initFreq: Freq | null = null

  private members: Contact[] = []
  private description = ''
  private isOnline = false
  private isLoadMembers = false
  private freq: Freq | null = null

  private hasError = false
  private descriptionError = false
  private currentDuration = 60

  private beforeSaveCallback: () => void = () => {}

  @Watch('description')
  validateDescription () {
    if (this.description) this.descriptionError = false
  }

  @Watch('tab')
  watchTab () {
    const meeting = this.updatedMeeting
    if (this.tab === 'cell' && meeting) {
      this.description = (meeting.displayName ?? '') + (meeting.description ?? '')
    }
  }

  get descriptionErrorMsg () {
    return !this.description
      ? this.$t('meetings.meetingDialog.descriptionErrorMsg').toString()
      : this.$t('meetings.meetingDialog.descriptionErrorMsgLength').toString()
  }

  private getNewDateWithDuration (dateStr: string, duration: number) {
    const date = new Date(dateStr)
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes() + duration,
    ).toISOString()
  }

  async created () {
    const meeting = meetingsStore.state.editingMeeting
    const me = this.currentTeam.me

    if (meeting) {
      this.description = (meeting.displayName ?? '') + (meeting.description ?? '')
      this.startDate = meeting.startAt
      this.freq = meeting.freq ?? null
      this.endDate = this.getNewDateWithDuration(this.startDate, meeting.duration)
      this.isOnline = !meeting.isOutside
      this.members = meeting.meetingMembers?.map(m => m.contact)
        .filter(m => m.jid !== me.jid) ?? []
      this.updatedMeeting = meeting
      this.isShowMode = !meeting.canEdit
      this.currentDuration = meeting.duration

      // if meeting is repeat edit cell else edit meeting instance
      this.tab = !this.freq ? 'all' : 'cell'
      this.initFreq = this.freq
    } else {
      const template = meetingsStore.state.meetingDialogTemplate
      const { description, members, startDate, endDate, beforeSave } = template

      if (description) this.description = description

      if (members) {
        this.members = members
          .filter((jid, i, a) => a.indexOf(jid) === i) // unique
          .map(jid => contactsStore.getters.contact(jid))
          .filter((member): member is Contact => !!member)
      }

      if (startDate) {
        this.startDate = startDate
      } else {
        const d = new Date()
        this.startDate = new Date(
          d.getFullYear(),
          d.getMonth(),
          d.getDate(),
          d.getHours() + 1,
          d.getMinutes() > 30 ? 0 : -30,
        ).toISOString()
      }

      if (endDate) {
        this.endDate = endDate
      } else {
        this.endDate = this.getNewDateWithDuration(this.startDate, 30)
      }

      if (beforeSave) this.beforeSaveCallback = beforeSave
    }
    this.isLoadMembers = true
  }

  private get endAt () {
    return this.endDate
  }

  private set endAt (value: string) {
    this.endDate = value
    this.hasError = this.duration < 0
  }

  private get startAt () {
    return this.startDate
  }

  private set startAt (value: string) {
    this.startDate = value
    this.endDate = this.getNewDateWithDuration(this.startDate, this.currentDuration)
  }

  private get duration () {
    const { getDateDiff } = date
    return getDateDiff(new Date(this.endDate), new Date(this.startDate), 'minutes')
  }

  private get isEditDialog () {
    return !!meetingsStore.state.editingMeeting
  }

  private get descriptionAndTitle () {
    const splitIndex = this.description.indexOf('\n')
    const title = splitIndex >= 0 ? this.description.substr(0, splitIndex) : this.description
    const description = splitIndex >= 0 ? this.description.substr(splitIndex) : ''
    return {
      description,
      title,
    }
  }

  private get currentTeam () {
    return teamsStore.getters.currentTeam
  }

  private get disabled () {
    return this.tab === 'next'
  }

  private get isEditingCell () {
    return this.tab === 'cell' && this.isEditDialog
  }

  private get isShowTabs () {
    return this.isEditDialog && this.initFreq
  }
}
